import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/login",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
    redirect: "follow",
  });
};
// Add the register method
AuthService.register = function (data) {
  return fetch({
    url: "/register",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
    redirect: "follow",
  });
};

// Reset Password method
AuthService.resetPassword = function (requestBody) {
  return fetch({
    url: "/reset_password",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(requestBody),
    redirect: "follow",
  });
};

// New forgot password function
AuthService.forgotPassword = function (requestData) {
  return fetch({
    url: "/forgot_password",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(requestData),
    redirect: "follow",
  });
};

AuthService.verifyEmail = function (data) {
  return fetch({
    url: "/verify_email",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export default AuthService;
