import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
  APP_PREFIX_PATH,
} from "configs/AppConfig";

const ProtectedRoute = () => {
  const type = useSelector((state) => state.auth.user_base_type);
  const is_verified = useSelector((state) => state.auth.is_verified);
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const setup = useSelector((state) => state.auth.completed_profile);
  const finalize_setup = useSelector((state) => state.auth.finalize_setup);

  // Check if the current pathname is the error page
  const onErrorPath = location.pathname === `${APP_PREFIX_PATH}/error/`;

  // Check if the current pathname is the setup page
  const onShipperSetupPath =
    location.pathname === `${APP_PREFIX_PATH}/shipper/setup/`;

  // Check if the current pathname is the setup page
  const onCarrierSetupPath =
    location.pathname === `${APP_PREFIX_PATH}/carrier/setup/`;

  const onShipperSetupErrorPath =
    location.pathname === `${APP_PREFIX_PATH}/shipper/setup-error-page/`;

  const onCarrierSetupErrorPath =
    location.pathname === `${APP_PREFIX_PATH}/carrier/setup-error-page/`;

  const onShipperAwaitingVerificationPath =
    location.pathname === `${APP_PREFIX_PATH}/shipper/verification-progress/`;

  const onCarrierAwaitingVerificationPath =
    location.pathname === `${APP_PREFIX_PATH}/carrier/verification-progress/`;

  const onShipperSetupSuccessPath =
    location.pathname === `${APP_PREFIX_PATH}/shipper/success-setup/`;

  const onCarrierSetupSuccessPath =
    location.pathname === `${APP_PREFIX_PATH}/carrier/success-setup/`;

  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  // If user is not setup and not verified, handle both shipper and carrier
  if (setup === false && is_verified === false && finalize_setup === false) {
    if (!onCarrierSetupPath && type === "carrier") {
      return <Navigate to={`${APP_PREFIX_PATH}/carrier/setup/`} replace />;
    } else if (!onShipperSetupPath && type === "shipper") {
      return <Navigate to={`${APP_PREFIX_PATH}/shipper/setup/`} replace />;
    }
  } else if (
    setup === false &&
    is_verified === false &&
    finalize_setup === true
  ) {
    if (!onShipperSetupSuccessPath && type === "shipper") {
      return (
        <Navigate to={`${APP_PREFIX_PATH}/shipper/success-setup/`} replace />
      );
    } else if (!onCarrierSetupSuccessPath && type === "carrier") {
      return (
        <Navigate to={`${APP_PREFIX_PATH}/carrier/success-setup/`} replace />
      );
    }
  }

  if (type === "shipper") {
    // Check for verification progress
    if (
      setup === true &&
      is_verified === false &&
      !onShipperAwaitingVerificationPath
    ) {
      return (
        <Navigate
          to={`${APP_PREFIX_PATH}/shipper/verification-progress/`}
          replace
        />
      );
    }

    // Check for setup error page
    if (setup === false && is_verified === true && !onShipperSetupErrorPath) {
      return (
        <Navigate to={`${APP_PREFIX_PATH}/shipper/setup-error-page/`} replace />
      );
    }
  }

  if (type === "carrier") {
    // Check for verification progress
    if (
      setup === true &&
      is_verified === false &&
      !onCarrierAwaitingVerificationPath
    ) {
      return (
        <Navigate
          to={`${APP_PREFIX_PATH}/carrier/verification-progress/`}
          replace
        />
      );
    }

    // Check for setup error page
    if (setup === false && is_verified === true && !onCarrierSetupErrorPath) {
      return (
        <Navigate to={`${APP_PREFIX_PATH}/carrier/setup-error-page/`} replace />
      );
    }
  }

  // Check if user's type allows access to current route
  const isAllowed =
    (type === "shipper" &&
      location.pathname.includes(`${APP_PREFIX_PATH}/shipper/`)) ||
    (type === "carrier" &&
      location.pathname.includes(`${APP_PREFIX_PATH}/carrier/`)) ||
    (type === "admin" &&
      location.pathname.includes(`${APP_PREFIX_PATH}/admin/`));

  // Redirect /app/shipper to /app/shipper/dashboard
  if (location.pathname === `${APP_PREFIX_PATH}/shipper/`) {
    return <Navigate to={`${APP_PREFIX_PATH}/shipper/dashboard/`} replace />;
  } else if (location.pathname === `${APP_PREFIX_PATH}/carrier/`) {
    return <Navigate to={`${APP_PREFIX_PATH}/carrier/dashboard/`} replace />;
  } else if (location.pathname === `${APP_PREFIX_PATH}/admin/`) {
    return <Navigate to={`${APP_PREFIX_PATH}/admin/dashboard/`} replace />;
  }
  if (!isAllowed) {
    // Redirect to error page if route is not allowed for user type
    if (!onErrorPath) {
      return <Navigate to={`${APP_PREFIX_PATH}/error/`} replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoute;
