import axios from "axios";
import { API_AUTH_URL } from "configs/AppConfig";
import { signOutSuccess } from "store/slices/authSlice";
import store from "../store";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const service = axios.create({
  baseURL: API_AUTH_URL,
  timeout: 60000, // Timeout after 60 seconds
});

// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }
    return config;
  },
  (error) => {
    // Handle request error here
    const { t } = useTranslation();
    notification.error({
      message: t("auth.request.error.title"), // Use translation function here
      description: t("auth.request.error.description"), // Use translation function here
    });
    return Promise.reject(error);
  }
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode = error.response?.status;
    // Handle other errors here globally
    if (statusCode === 404) {
      let notificationParam = {
        message: "An error occurred. Please try again or contact support",
      };
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOutSuccess());
      notification.error(notificationParam);
    }

    if (statusCode === 508) {
      let notificationParam = {
        message: "Time Out",
      };
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(signOutSuccess());
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
